<template>
  <div style="width: 100%; height: 100%;">
    <v-img class="d-flex align-center justify-center" align="center" src="/background-img.png" height="100%" width="100%">
      <v-img class="align-center" width="688px" style="position: relative;" src="/login.png">
        <v-btn  class="col-6"  @click="login">login Meta Mask</v-btn>
      </v-img>

    </v-img>
  </div>

</template>

<script>
import {MoralisInstance} from "@/js/nft/single";

export default {
  name: "Login",
  data() {
    return {
      username: ""
    }
  },
  methods: {
    async login() {
      let user: MoralisInstance.User = null// MoralisInstance.User.current();
      if (!user) {
        user = await MoralisInstance.authenticate({signingMessage: "Log in using Moralis"})
            .catch(function (error) {
              console.log(error);
              if (error.toString().indexOf('Non ethereum enabled browser') > -1) {
                window.open('https://metamask.io/download.html')
              }
            });
      }
      if (user) {
        console.log(user.get('ethAddress'))
        localStorage.setItem('ethAddress', user.get('ethAddress'))
        await this.$router.push('/nft').then().catch(erro => console.log(erro))
      }
    },

    async logOut() {
      localStorage.removeItem('ethAddress')
      MoralisInstance.User.logOut()
    }
  }
}
</script>

<style scoped>

</style>
